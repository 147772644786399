import React, { Component } from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
// eslint-disable-next-line no-unused-vars
import { IApplicationSettings } from '../helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faTwitter, faLinkedin, faStackOverflow } from '@fortawesome/free-brands-svg-icons'
import { TelemetryService } from '../telemetry/service'

export class Navigation extends Component<{ app: IApplicationSettings }> {
  private telemetry = TelemetryService.init();

  render () {
    return <Navbar bg="dark" variant="dark" sticky="top" expand="lg" style={{ marginLeft: 0, marginRight: 0 }} >
      <Navbar.Brand href="/">
        <img
          alt=""
          src={this.props.app.logoPath}
          width="25"
          height="30"
          className="d-inline-block align-top"
        />{' '}
        {this.props.app.brandName}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto" >
          <Nav.Link href="/#services" onClick={() => this.telemetry.trackEvent('navigation', { origin: 'navbar', target: 'home/services' })}>Services</Nav.Link>
          <Nav.Link href="/#about" onClick={() => this.telemetry.trackEvent('navigation', { origin: 'navbar', target: 'home/about' })}>About</Nav.Link>
          <Nav.Link href="/#contact" onClick={() => this.telemetry.trackEvent('navigation', { origin: 'navbar', target: 'home/contact' })}>Contact</Nav.Link>
        </Nav>
        <Nav >
          <Nav.Link href="https://github.com/nielzeeman" target="_blank"><FontAwesomeIcon icon={faGithub} size="1x" style={{ color: '#dddddd' }} /></Nav.Link>
          <Nav.Link href="https://twitter.com/teamfoundation" target="_blank"><FontAwesomeIcon icon={faTwitter} size="1x" style={{ color: '#dddddd' }} /></Nav.Link>
          <Nav.Link href="https://www.linkedin.com/in/nielzeeman/" target="_blank"><FontAwesomeIcon icon={faLinkedin} size="1x" style={{ color: '#dddddd' }} /></Nav.Link>
          <Nav.Link href="https://stackoverflow.com/users/5422113/niel-zeeman" target="_blank"><FontAwesomeIcon icon={faStackOverflow} size="1x" style={{ color: '#dddddd' }} /></Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  }
}
