import React, { Component } from 'react'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'

interface ICompanyDetail {
    name: string;
    logo: string;
    background: boolean;
    uri: string;
    key:string;
}
export class CompaniesMarquee extends Component {
    companies: ICompanyDetail[][] = [[
      { key: 'coy_key1', name: 'Club Tech', logo: 'img/coy/clubtech.png', background: false, uri: 'https://www.clubtech.co.za' },
      { key: 'coy_key2', name: 'Glacier', logo: 'img/coy/glacier.png', background: true, uri: 'https://www.glacier.co.za/' },
      { key: 'coy_key3', name: 'IDU', logo: 'img/coy/idu.png', background: false, uri: 'https://www.idusoft.com/' }
    ], [
      { key: 'coy_key4', name: 'Mint Group', logo: 'img/coy/mint.png', background: false, uri: 'https://www.mintgroup.net/' },
      { key: 'coy_key5', name: 'Mpact', logo: 'img/coy/mpact.png', background: false, uri: 'https://www.mpact.co.za/' },
      { key: 'coy_key6', name: 'Old Mutual', logo: 'img/coy/old-mutual.png', background: false, uri: 'https://www.oldmutual.co.za' }
    ], [
      { key: 'coy_key7', name: 'Pragma', logo: 'img/coy/pragma.png', background: true, uri: 'https://www.pragmaworld.net/' },
      { key: 'coy_key8', name: 'Principa', logo: 'img/coy/principa.png', background: false, uri: 'https://www.principa.co.za/' },
      { key: 'coy_key9', name: 'Qorus Docs', logo: 'img/coy/qorus-docs.png', background: false, uri: 'https://www.qorusdocs.com/' }
    ], [
      { key: 'coy_key10', name: 'SA Metal', logo: 'img/coy/sa-metal.png', background: true, uri: 'https://www.sametal.co.za/' },
      { key: 'coy_key11', name: 'Super Group', logo: 'img/coy/supergroup.png', background: false, uri: 'http://www.supergroup.co.za/' },
      { key: 'coy_key12', name: 'The Foschini Group', logo: 'img/coy/tfg.png', background: false, uri: 'https://www.tfg.co.za/' }
    ], [
      { key: 'coy_key13', name: 'Tracker Connect', logo: 'img/coy/tracker-connect.png', background: false, uri: 'https://www.tracker.co.za/' },
      { key: 'coy_key14', name: 'Unitrans', logo: 'img/coy/unitrans.png', background: false, uri: 'https://www.unitrans.co.za/' }
    ]];

    private renderRows () {
      return this.companies.map(rows => {
        const hash = rows.map(r => r.key).join()
        return <Row key={hash}>{this.renderColumns(rows)}</Row>
      })
    }

    private renderColumns (companyDetails: ICompanyDetail[]) {
      return companyDetails.map(column => {
        const backStyle = (column.background) ? { backgroundColor: 'silver', maxHeight: '50px' } : { maxHeight: '50px' }

        return <Col xs={6} md={4} style={{ justifyContent: 'center', alignItems: 'center', padding: '5px', textAlign: 'center' }} key={column.key}>
          <a href={column.uri} target="_blank" rel="noopener noreferrer">
            <Image src={column.logo} alt={column.name} fluid rounded style={backStyle} key={`${column.key}_col`}/>
          </a>
        </Col>
      })
    }

    render () {
      return <Container style={{ backgroundColor: 'white', borderRadius: '.25rem', height: '100%' }} >
        <Row style={{ height: '100%' }}>
          <Card style={{ width: '100%', padding: '1rem' }}>
            <Card.Title className="text-center">Just some of our valued customers</Card.Title>
            <Card.Body>
              {this.renderRows()}
            </Card.Body>
          </Card>
        </Row>
      </Container>
    }
}
