import React, { Component } from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faTwitter, faLinkedin, faStackOverflow } from '@fortawesome/free-brands-svg-icons'
// eslint-disable-next-line no-unused-vars
import { IApplicationSettings } from '../helpers'

export class Footer extends Component<{app:IApplicationSettings}> {
  render () {
    return <Navbar bg="light" variant="light" expand="lg" style={{ marginLeft: 0, marginRight: 0 }} >
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto" >
          <Nav.Item>{'\u00A9'} {this.props.app.brandName} 2022</Nav.Item>
        </Nav>
        <Nav >
          <Nav.Link href="https://github.com/nielzeeman" target="_blank"><FontAwesomeIcon icon={faGithub} size="1x" style={{ color: '#aaaaaa' }} /></Nav.Link>
          <Nav.Link href="https://twitter.com/teamfoundation" target="_blank"><FontAwesomeIcon icon={faTwitter} size="1x" style={{ color: '#aaaaaa' }} /></Nav.Link>
          <Nav.Link href="https://www.linkedin.com/in/nielzeeman/" target="_blank"><FontAwesomeIcon icon={faLinkedin} size="1x" style={{ color: '#aaaaaa' }} /></Nav.Link>
          <Nav.Link href="https://stackoverflow.com/users/5422113/niel-zeeman" target="_blank"><FontAwesomeIcon icon={faStackOverflow} size="1x" style={{ color: '#aaaaaa' }} /></Nav.Link>
        </Nav>
        <Nav>
          <Nav.Item><span style={{ fontSize: '8px', color: '#aaaaaa' }}>v1.6</span></Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  }
}
