import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// eslint-disable-next-line no-unused-vars
import { IconDefinition, faCogs, faHistory, faCloud, faCode, faCodeBranch } from '@fortawesome/free-solid-svg-icons'

import Card from 'react-bootstrap/Card'
import CardDeck from 'react-bootstrap/CardDeck'

import { TelemetryService } from '../telemetry/service'

export class Services extends Component {
  private telemetry = TelemetryService.init();

  services: IServiceItem[] = [
    { name: 'DevOps Services', description: 'DevOps Consulting<br/>DevOps Guidance and Mentorship<br/>DevOps Training<br/>GitHub Basics', icon: faCogs, id: 'devops', additionalInfo: '/services#devops' },
    { name: 'Agile Adoption Mentorship', description: 'Evaluate, adopt and implement modern agile methodologies and processes.<br/>Team guidance through the process and steps in adopting a modern agile based process or methodology', icon: faHistory, id: 'mentorship' },
    { name: 'Application Modernisation Consulting', description: 'Guidance on modernising your application and introducing cloud native concepts.<br/>I have a broad knowledge of modern architectures, containers and cloud (Azure) based services.<br/>I can help map your existing application onto modern cloud native architectures!', icon: faCloud, id: 'modernisation' },
    { name: 'Software Architecture Services', description: 'Code and architecture reviews and evaluations to give you an impartial third party view of your codebase and ensure that your software architecture is in line with good practices and industry standards.<br/>Improve performance, leverage the right tools and technologies, choose the right hosting environment.', icon: faCodeBranch, id: 'architecture' },
    { name: 'Development Services', description: 'Do you have a great idea or want to see how a new technology or concept will fit into your business/application but to not have the developers/time to do it yourself?<br/>I develop small scale proof of concepts using new processes and technologies for you to evaluate.', icon: faCode, additionalInfo: '/services#development', id: 'development' }
  ];

  renderCards () {
    return this.services.map(service => {
      let footer = null
      if (service.additionalInfo != null) {
        footer = <Card.Link href={service.additionalInfo} style={{ textAlign: 'right' }} onClick={() => this.telemetry.trackEvent('navigation', { origin: 'service detail', target: service.name })}>More...</Card.Link>
      }

      return <Card border="primary" style={{ minHeight: '150px' }} key={service.id} >
        <Card.Body>
          <Card.Title><FontAwesomeIcon icon={service.icon} />{' '}{service.name}</Card.Title>
          <Card.Text dangerouslySetInnerHTML={{ __html: service.description }}>
          </Card.Text>
        </Card.Body>
        {footer}
      </Card>
    })
  }

  render () {
    return <CardDeck >{this.renderCards()}</CardDeck>
  }
}

interface IServiceItem {
  name: string;
  description: string;
  icon: IconDefinition;
  additionalInfo?: string;
  id: string;
}
