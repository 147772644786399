import React from 'react'

import { ContactForm } from './contactform'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'

export default class SkillsMatrix extends React.Component {
  render () {
    return <Container id='skills'>
      <Row>
        <Col>
          <p>Here is a not-so comprehensive list of things that I have worked in and with. <br />
           I&apos;m always looking to broaden my knowldge and skills, and will take each initiative as a challenge to deliver the best value possible.</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table>
            <thead>
              <tr>
                <th>Things I&apos;ve done a lot of...</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <ul>
                    <li>Azure DevOps</li>
                    <li>GitHub</li>
                    <li>Software Architecture</li>
                    <li>Application Lifecycle Management</li>
                    <li>C#</li>
                    <li>.Net &amp; .Net Core</li>
                    <li>Microservices</li>
                    <li>Docker</li>
                    <li>Scrum and Agile workshops</li>
                    <li>Object Oriented Design</li>
                    <li>CI/CD</li>
                    <li>PowerShell</li>
                    <li>Containerisation (Docker / Kubernetes)</li>
                    <li>Application modernisation</li>
                    <li>SQL</li>
                    <li>Azure</li>
                    <li>ReactJS</li>
                    <li>Typescript</li>
                    <li>Code reviews</li>
                    <li>Present technical content at office groups all the way to technology events and conferences</li>
                  </ul></td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th>Things I know a bit of...</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <ul>
                    <li>IoT </li>
                    <li>AI and Machine Learning</li>
                    <li>AWS</li>
                    <li>Java (Spring Boot)</li>
                    <li>Grafana</li>
                  </ul>
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th>Things I know enough of not to put in the lists above...</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <ul>
                    <li>Dynamics CRM</li>
                    <li>Delphi</li>
                    <li>PHP</li>
                    <li>Ruby</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row style={{ minHeight: '400px', alignContent: 'center' }}>
        <Col style={{ width: '100%' }} >
          <ContactForm source='skills' header='If you have any questions or challenges, please feel free to reach out and let&apos;s talk...' />
        </Col>
      </Row>
    </Container>
  }
}
