import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'

const browserHistory = createBrowserHistory()

export class TelemetryService {
    private telemetryService: ApplicationInsights;

    public reactPlugin : ReactPlugin;

    constructor () {
      this.reactPlugin = new ReactPlugin()

      this.telemetryService = new ApplicationInsights({
        config: {
          instrumentationKey: `${process.env.REACT_APP_TELEMETRY_KEY}`,
          extensions: [this.reactPlugin],
          extensionConfig: {
            [this.reactPlugin.identifier]: { history: browserHistory }
          }
        }
      })
      this.telemetryService.loadAppInsights()
    }

    public trackEvent (name: string, custom: any) {
      this.telemetryService.trackEvent({ name: name }, custom)
    }

    private static service: TelemetryService;

    public static init (): TelemetryService {
      if (TelemetryService.service == null) {
        TelemetryService.service = new TelemetryService()
      }
      return TelemetryService.service
    }
}
