export interface IApplicationSettings {
  brandName: string;
  logoPath: string;
}

export class Settings {
  static getSettings (): IApplicationSettings {
    return {
      brandName: 'Team Foundation Consulting',
      logoPath: './img/logo_icon.png'
    }
  }
}
