import React from 'react'
import { Routes, Route } from 'react-router'
import './App.css'

import { Settings } from './helpers'

import { Navigation } from './components/navigation'

import { Mainpage } from './components/mainpage'
import { ServiceDetails } from './components/servicedetail'
import SkillMatrix from './components/skillsmatrix'

import { Footer } from './components/footer'

import { TelemetryService } from './telemetry/service'
import { withAITracking } from '@microsoft/applicationinsights-react-js'

const appSettings = Settings.getSettings()
const telemetry = TelemetryService.init()

function App () {
  if (window.location.href.indexOf('//teamfoundation.co.za') > 0) {
    window.location.href = 'https://www.teamfoundation.co.za'
  }
  return (
    <div>
      <Navigation app={appSettings} />

      <Routes >
        <Route path='/' element={<Mainpage />}></Route>
        <Route path='/services' element={<ServiceDetails />}></Route>
        <Route path='/skills' element={<SkillMatrix />}></Route>
      </Routes >

      <Footer app={appSettings} />
    </div>
  )
}

export default withAITracking(telemetry.reactPlugin, App)
