// eslint-disable-next-line no-unused-vars
import React, { Component, CSSProperties } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/esm/Image'
import { Link } from 'react-router-dom'

interface ITimerIncrement {
  remaininSeconds: number;
  currentIndex: number;
}
export class About extends Component<any, ITimerIncrement> {
  private rowStyle: CSSProperties = {
    marginTop: '2px'
  }

  private rowSpacer: any = <Row style={{ height: '20px' }}>{' '}</Row>;

  private titles: string[] = ['solution architect',
    'process engineer',
    'DevOps architect',
    'full stack developer',
    'AI/IoT developer'];

  private timer: any;
  constructor (props: any) {
    super(props)
    this.state = { remaininSeconds: 5, currentIndex: 0 }
  }

  componentDidMount () {
    this.timer = setInterval(() => {
      this.decrementTimeRemaining()
    }, 1000)
  }

  private decrementTimeRemaining () {
    let indx = this.state.currentIndex + 1
    if (indx >= this.titles.length) {
      indx = 0
    }
    this.setState({
      currentIndex: indx
    })
  }

  render () {
    return <Container>
      <Row className="justify-content-md-center" style={{ alignItems: 'center', alignContent: 'center' }} >
        <Col>
          <Row className="justify-content-md-center" style={{ alignItems: 'center', alignContent: 'center' }}><Image alt="Niel Zeeman" src="./img/niel_zeeman.jpg" roundedCircle style={{ marginRight: '3px' }} /><h2> Niel Zeeman. Owner of Team Foundation Consulting.</h2></Row>
          {this.rowSpacer}
          <Row style={this.rowStyle} >I spent the beginning of my career as a software developer where I saw the good the bad and the ugly of the software development process. Before starting my own consultancy, my last job literally saw me attending to multiple clients as projects went south and problems put pressure on deliverable timelines. I was the person who was sent in to get things back on track so that the team could actually deliver on the client&apos;s requirements.</Row>
          <Row style={this.rowStyle}>Whilst I was promoted to 2IC to the technical director, after a while, there was little satisfaction in constantly putting out other people&apos;s fires…every day...again and again.</Row>
          <Row style={this.rowStyle}>I left my job to pursue a better way of managing the development process, a way that helped companies get the results they wanted and allowed developers to deliver within their timeframes and expectations.</Row>
          <Row style={this.rowStyle}>I began providing training and consulting for Azure DevOps, then TFS, and today I still help teams define their processes, reduce impediments and make Agile delivery really work for all stakeholders.</Row>
          <Row style={this.rowStyle}>I am interested in passion projects, and regularly take on smaller tasks to help entrepreneurs do greater things. This includes process guidance and improvement as well as development work and proof of concepts.</Row>
          <Row style={this.rowStyle}>I have done software reviews/audits to help clients know what they are getting into and what to expect in terms of the legacy system they inherited.</Row>
          <Row style={this.rowStyle}>AI and IoT are other interests I purse. I&apos;m quite chuffed that my AI proof of concept for a client won a best in show award in 2019…even if it&apos;s all under NDA. That talking bot used Azure cognitive services to recognise speech and identify the individual and provide intelligent feedback.</Row>
          <Row style={this.rowStyle}>I&apos;m a lot of things to a lot of people. Take a look at some of <Link to='/skills#skills' style={{ marginLeft: '0.25rem' }}>{' '}my skills</Link>.</Row>
          <Row style={this.rowStyle}>I&apos;m sure I can help you, but if I can&apos;t I will tell you, and I might just be able to refer you to someone who can help.</Row>
          {this.rowSpacer}
          <Row className="justify-content-md-center"><h3>Niel Zeeman, a {this.titles[this.state.currentIndex]} who can plug your holes and put out your fires...</h3></Row>
        </Col>
      </Row>
    </Container>
  }
}
