import React, { Component } from 'react'
import './servicedetails.css'

import Card from 'react-bootstrap/esm/Card'
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCogs, faHistory, faCloud, faCode, faCodeBranch } from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { ContactForm } from './contactform'
import { Link } from 'react-router-dom'

export class ServiceDetails extends Component {
  render () {
    return <div>
      <Card id="devops" className="content_block servicecard">
        <Card.Title>
          <FontAwesomeIcon icon={faCogs} /> DevOps Services
        </Card.Title>
        <Card.Body>
          My primary focus over the last number of years has been in the DevOps space. Here are some of the reasons you should get hold of me:

          <Accordion>
            <Card id="devops_consulting" >
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                  <FontAwesomeIcon icon={faPlus} /> DevOps Consulting
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>I will help you build and scale your DevOps solution.</p>
                  <p>DevOps Consulting encompasses: </p>
                  <ul>
                    <li>Setup, migrate, maintain or upgrade Azure DevOps</li>
                    <li>Source control</li>
                    <li>Automated deployment tools and techniques</li>
                    <li>CI/CD (Continuous Integration / Continuous deployment) pipelines</li>
                    <li>Containerisation, Docker, Kubernetes</li>
                    <li>Setup monitoring, alerts, build and deploy, auto testing</li>
                    <li>Analytics and reporting</li>
                    <li>PowerShell scripting</li>
                    <li>Git</li>
                    <li>Configuration Management with DSC, ARM or Helm</li>
                    <li>Breaking monoliths into Microservices</li>
                    <li>Recommend suitable infrastructure</li>
                    <li>Review and optimise business processes</li>
                    <li>Recommend architectural improvements</li>
                    <li>Investigate and resolve technical issues</li>
                    <li>Educate on implemented tools and service</li>
                    <li>Azure Cloud Infrastructure</li>
                  </ul>

                </Card.Body></Accordion.Collapse>
            </Card>
            <Card id="devops_mentor">
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  <FontAwesomeIcon icon={faPlus} /> DevOps Guidance and Mentorship – Remote Services
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>Due to the Covid-19 pandemic, on-site training workshops have taken a back seat.
                    Increasingly I am fulfilling a guidance and mentorship role to individuals and companies who want to improve their DevOps process and understanding, in a step-by-step manner, at their own pace.</p>
                  <p>Remote services allow me to address your issues when it suits you. I facilitate small guidance sessions, lasting 30 minutes to 1 hour, as well as extended mentorship engagements with scheduled weekly sessions following your team&apos;s improvements and helping to alleviate new challenges as they progress.</p>
                  <p>As we exit lockdown, on-site training workshops will be made available again.</p>
                  <p>In the meantime I am happy to offer you guidance and mentorship via remote services.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card id="devops_workshop" >
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                  <FontAwesomeIcon icon={faPlus} /> DevOps Training Workshops – On-site
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  Are you new to DevOps? The <Link to="./files/Azure-DevOps-Training-Schedule.pdf" target="_blank">standard training schedule</Link> is ideal for the team starting out with Azure DevOps. <br />It covers topics such as:
                  <ul>
                    <li>Current features in Azure DevOps</li>
                    <li>Product backlog and sprint planning</li>
                    <li>Test plans, test suites and test case management</li>
                    <li>Using repositories effectively, including branching structures</li>
                    <li>Setting up automated deployment, CI/CD</li>
                  </ul>
                  Do you need training that is more specific or more advanced? The standard training schedule can be customised to suit your team&apos;s skill level and requirements. We can also focus on architecture, process and methodologies (Scrum, Agile etc.), developer practices and/or tools and technologies.<br></br>
                  Training workshops are presented on-site at your location.
                </Card.Body></Accordion.Collapse>
            </Card>

            <Card id="devops_git">
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                  <FontAwesomeIcon icon={faPlus} /> GitHub Basics Overview
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  In this 1 hour session I take you through the basics of Git and GitHub. We&apos;ll look at:
                  <ul>
                    <li>Creating a new repository</li>
                    <li>The basics around setting yourself up for a good branching strategy</li>
                    <li>A few of the basic Git commands</li>
                    <li>An introduction to GitHub action for your CI (continuous integration) needs</li>
                  </ul>
                </Card.Body></Accordion.Collapse>
            </Card>
          </Accordion>
        </Card.Body>
      </Card>
      <Card id="mentorship" className="content_block servicecard">
        <Card.Title>
          <FontAwesomeIcon icon={faHistory} />  Agile Adoption Mentorship
        </Card.Title>
        <Card.Body>
          I can help take your team through the process and steps in adopting a modern agile based process or methodology.
          I provide workshops and mentorship in the multiple ceremonies, always focussed on what the right ones are for your environment to give you the best results.        </Card.Body>
      </Card>
      <Card id="modernisation" className="content_block servicecard">
        <Card.Title>
          <FontAwesomeIcon icon={faCloud} /> Application Modernisation Consulting
        </Card.Title>
        <Card.Body>
          <p>What does a modern application look like? What does it look like to develop cloud native solutions?
            How can I leverage my current application architecture and create a modern cloud native application? </p>
          <p>These are some of the answers that I can provide.
            I have a broad knowledge of modern architectures, containers and cloud (Azure) based services.
            I can help map your existing application onto modern cloud native architectures!</p>
        </Card.Body>
      </Card>
      <Card id="architecture" className="content_block servicecard">
        <Card.Title>
          <FontAwesomeIcon icon={faCodeBranch} /> Software Architecture Services
        </Card.Title>
        <Card.Body>
          <p>Have you wondered how your codebase stacks up to modern patterns? Is it in a position to grow with your business? Are you using the right technology for the right reasons?</p>
          <p>I perform code and architecture reviews to give you an impartial third party view of your codebase. This can help ensure that your software architecture is in line with good practices and industry standards. I can provide insight and recommendations to improve performance, leverage the right tools and technologies, and choose the right hosting environment.</p>
        </Card.Body>
      </Card>
      <Card id="development" className="content_block servicecard">
        <Card.Title>
          <FontAwesomeIcon icon={faCode} /> Development Services
        </Card.Title>
        <Card.Body>
          <p>Are you thinking about this AI thing and not sure what is possible or where to start? Are you considering some IoT concepts, and not sure if they will work?  Do you not have the time or resources to get answers to these questions?</p>
          <p>I can provide you the insight that you need to give the go-ahead or pull the plug on projects before you spend too much.  In my development career I am always looking for better, easier and quicker ways to do things.
            I can provide development services for those small projects or proof-of-concepts that you never seem to be able to get to.They might be the one thing that pushes your company ahead of your competitors!</p>
          <p>Take a look at some of <Link to='/skills#skills'>my skills</Link>.</p>
        </Card.Body>
      </Card>

      <Container style={{ marginTop: '1rem' }} >
        <Row style={{ minHeight: '400px', alignContent: 'center' }}>
          <Col style={{ width: '100%' }} >
            <ContactForm source='services' />
          </Col>
        </Row>
      </Container>

    </div >
  }
}
