import React, { Component } from 'react'

import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Button from 'react-bootstrap/Button'
import Overlay from 'react-bootstrap/Overlay'
import Tooltip from 'react-bootstrap/Tooltip'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'
import Card from 'react-bootstrap/Card'

import ReCAPTCHA from 'react-google-recaptcha'

interface ValidationState {
  valid: boolean;
  submitting: boolean;
  submitted: boolean;
  submitSuccess: string;
  name?: string;
  email?: string;
  contact?: string;
  company?: string;
  message?: string;
  source: string;
  header: string;
  capcha?: string;
}

interface IContactProperties {
  source: string;
  header?: string;
}

export class ContactForm extends Component<IContactProperties, ValidationState> {
  private recaptchaRef: React.RefObject<ReCAPTCHA>;
  private overLayTarget: React.RefObject<HTMLButtonElement>;
  private showError: boolean = false;

  private contactFormSubmitUri: string = '/api/ContactForm';
  private reCaptchaSiteKey: string = `${process.env.REACT_APP_RECAPTCHA_KEY}`;

  constructor (props: IContactProperties) {
    super(props)
    this.recaptchaRef = React.createRef()
    this.overLayTarget = React.createRef()
    console.log('ctor called:')
    this.state = { valid: false, submitting: false, submitted: false, submitSuccess: 'none', source: props.source, header: (props.header == null) ? 'If you have any questions, please feel free to contact me:' : props.header }
    this.onChange = this.onChange.bind(this)
  }

  private onChange (value: any) {
    this.setState({ ...this.state, [value.currentTarget.name]: value.currentTarget.value })
  };

  handleSubmit (event: any) {
    this.showError = false
    event.preventDefault()
    event.stopPropagation()

    const form = event.currentTarget
    if (this.recaptchaRef.current != null) {
      const recaptchaValue = this.recaptchaRef.current.getValue()

      if ((form.checkValidity() === false) || (recaptchaValue === '')) {
        this.setState({ valid: false, submitting: false, submitted: false })
        this.showError = true
      } else {
        this.setState({ valid: true, submitting: true, submitted: false })

        var message = {
          name: this.state.name,
          email: this.state.email,
          contact: this.state.contact,
          company: this.state.company,
          message: this.state.message,
          source: this.state.source,
          capcha: recaptchaValue
        }

        fetch(this.contactFormSubmitUri, {
          method: 'POST',
          body: JSON.stringify(message)
        })
          .then(response => {
            console.log(response)
            this.setState({ submitted: true, submitSuccess: 'OK' })
          })
          .catch(err => {
            console.log(err)
            this.setState({ submitted: true, submitSuccess: 'ERROR' })
          })
      }
    }
  }

  render () {
    let form = this.getForm()
    if (this.state.submitted && this.state.submitSuccess === 'OK') {
      form = <Alert variant="primary" style={{ margin: '20px' }}>Thank you for reaching out, we will be in contact shortly!</Alert>
    } else if (this.state.submitted && this.state.submitSuccess === 'ERROR') {
      form = <Container><Row><Alert variant="danger" >Something seems to have gone wrong, please try again.</Alert></Row><Row>{form}</Row></Container>
    }

    return <Container style={{ backgroundColor: 'white', borderRadius: '.25rem' }} >
      <Row>
        <Card style={{ width: '100%', padding: '1rem' }}>
          <Card.Title className="text-center">{this.state.header}</Card.Title>
          <Card.Body>
            {form}
          </Card.Body>
        </Card>

      </Row>
    </Container >
  }

  private getForm () {
    let submitted = null
    if (!this.state.submitted && this.state.submitting) {
      submitted = <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    }

    return <Form style={{ margin: '5px', width: '90%' }} onSubmit={(v) => this.handleSubmit(v)}>
      <Form.Group controlId="formFullName" >
        <Form.Control type="text" placeholder="Enter your name (*)" required size="sm" name="name" onChange={this.onChange} />
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formEmail" >
        <Form.Control type="email" placeholder="Enter email (*)" required size="sm" name="email" onChange={this.onChange} />
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formContactNumber" >
        <Form.Control type="text" placeholder="Contact #" size="sm" name="contact" onChange={this.onChange} />
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formCompany" >
        <Form.Control type="text" placeholder="Company Name" size="sm" name="company" onChange={this.onChange} />
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formMessage" >
        <Form.Control as="textarea" size="sm" placeholder="Message or query (*)" required name="message" onChange={this.onChange} ></Form.Control>
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <ReCAPTCHA
          ref={this.recaptchaRef}
          sitekey={this.reCaptchaSiteKey}
        />
      </Form.Group>
      <Button variant="primary" type="submit" ref={this.overLayTarget} disabled={this.state.submitted} >
        {submitted}
        {' '}Send
      </Button>
      <Overlay target={this.overLayTarget.current} show={this.showError} placement="right">
        {(props) => (
          <Tooltip id="recaptcha-error" {...props}>
            Please complete all required (*) fields
          </Tooltip>
        )}
      </Overlay>
    </Form>
  }
}
