import React, { Component } from 'react'
import { About } from './about'
import { Services } from './services'
import { ContactForm } from './contactform'
import { CompaniesMarquee } from './companies'

import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'

export class Mainpage extends Component {
  render () {
    return <Container style={{ width: '100%', margin: 0 }} fluid={true}>
      <Row className="parallax_section" style={{ minHeight: '300px', paddingTop: '100px', paddingBottom: '100px' }} id="services">
        <Col><Services /></Col>
      </Row>
      <Row className="content_block" style={{ minHeight: '400px', paddingTop: '100px', paddingBottom: '100px' }} id="about">
        <Col><About /></Col></Row>
      <Row className="parallax_section" style={{ paddingTop: '100px', paddingBottom: '100px' }} id="contact">
        <Col>
          <Container fluid={true} >
            <Row >
              <Col style={{ width: '50%' }} ><ContactForm source='mainpage' /></Col>
              <Col style={{ width: '50%' }}><CompaniesMarquee /></Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  }
}
